import React, { Component } from "react";
import BackendService from "./Backend/backend-service";

const backendService = new BackendService();

export default class Home extends Component {
  state = {
    full_name: "",
    restaurant_name: "",
    city: "",
    mobile: "",
    interested_in: "",
    s_msg: "",
    hide: false,
    error: false,
    err_restaurant: "",
    err_city: "",
    err_mobile: "",
    admin_ios: "#",
    admin_android: "#",
    captain_android: "#",
    captain_win: "#",
  };

  componentDidMount() {
    this.load_download_links();
  }

  load_download_links() {
    backendService.load_download_links().then((r) => {
      if (r.error == "False") {
        this.setState({
          admin_ios: r.admin_ios,
          admin_android: r.admin_android,
          captain_android: r.captain_android,
          captain_win: r.captain_win,
        });
      }
    });
  }

  request_demo() {
    if (this.state.restaurant_name == "") {
      this.setState({
        err_restaurant: "Please Fill This Field",
        err_city: "",
        err_mobile: "",
      });
    } else if (this.state.city == "") {
      this.setState({
        err_city: "Please Fill This Field",
        err_restaurant: "",
        err_mobile: "",
      });
    } else if (this.state.mobile == "") {
      this.setState({
        err_mobile: "Please Fill This Field",
        err_city: "",
        err_restaurant: "",
      });
    } else if (this.state.mobile.length != 10) {
      this.setState({
        err_mobile: "Please Fill Mobile No. With 10 Digit",
        err_city: "",
        err_restaurant: "",
      });
    } else {
      var data = {
        restaurant_name: this.state.restaurant_name,
        city: this.state.city,
        mobile: this.state.mobile,
      };

      backendService.request_demo(data).then((r) => {
        if (r.error == "False") {
          this.setState({
            hide: true,
            error: false,
            s_msg: "Thank You For Submit Your Request For Demo.",
            s_msg_2: " We Will Reach You Within 24 Hour.",
            full_name: "",
            restaurant_name: "",
            city: "",
            mobile: "",
            interested_in: "",
          });
        } else {
          this.setState({
            hide: true,
            error: true,
            s_msg: "Something Went Wrong.",
            s_msg_2: " Please Try Again",
          });
        }
      });
    }
  }

  render() {
    return (
      <div>
        <div class="spinner-wrapper">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>

        <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
          <a class="navbar-brand logo-image" href="index.html">
            <img src="images/postick.png" alt="alternative" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-awesome fas fa-bars"></span>
            <span class="navbar-toggler-awesome fas fa-times"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link page-scroll" href="#header">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="#services">
                  Services
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="#pricing">
                  Pricing
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="#request">
                  Request
                </a>
              </li>

              {/* <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle page-scroll"
                  href="#about"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="terms-conditions.html">
                    <span class="item-text">Terms Conditions</span>
                  </a>
                  <div class="dropdown-items-divide-hr"></div>
                  <a class="dropdown-item" href="privacy-policy.html">
                    <span class="item-text">Privacy Policy</span>
                  </a>
                </div>
              </li> */}

              <li class="nav-item">
                <a class="nav-link page-scroll" href="#contact">
                  Contact
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="#download">
                  Download
                </a>
              </li>
            </ul>
            <span class="nav-item social-icons">
              <span class="fa-stack">
                <a
                  target="_blank"
                  href="https://www.facebook.com/Postick-110506818235650"
                >
                  <i class="fas fa-circle fa-stack-2x facebook"></i>
                  <i class="fab fa-facebook-f fa-stack-1x"></i>
                </a>
              </span>
              <span class="fa-stack">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/postick-pos-70649a234/"
                >
                  <i class="fas fa-circle fa-stack-2x linkedin"></i>
                  <i class="fab fa-linkedin fa-stack-1x"></i>
                </a>
              </span>
            </span>
          </div>
        </nav>

        <header id="header" class="header">
          <div class="header-content">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="text-container">
                    <h1>
                      <span class="turquoise">POSTICK</span> Restaurant
                      Management Solutions
                    </h1>
                    <p class="p-large">
                      Cloud Based Application For Enchanced Analysis With Trail
                      Period of 28 Days.
                    </p>
                    <a class="btn-solid-lg page-scroll" href="#request">
                      Get Demo Now
                    </a>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="image-container">
                    <img
                      class="img-fluid"
                      src={require("./images/Waiters-bro.png")}
                      alt="alternative"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* <div class="slider-1">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h5>Trusted By</h5>

                <div class="slider-container">
                  <div class="swiper-container image-slider">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div class="image-container">
                          <img
                            class="img-responsive"
                            src="images/customer-logo-1.png"
                            alt="alternative"
                          />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="image-container">
                          <img
                            class="img-responsive"
                            src="images/customer-logo-2.png"
                            alt="alternative"
                          />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="image-container">
                          <img
                            class="img-responsive"
                            src="images/customer-logo-3.png"
                            alt="alternative"
                          />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="image-container">
                          <img
                            class="img-responsive"
                            src="images/customer-logo-4.png"
                            alt="alternative"
                          />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="image-container">
                          <img
                            class="img-responsive"
                            src="images/customer-logo-5.png"
                            alt="alternative"
                          />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="image-container">
                          <img
                            class="img-responsive"
                            src="images/customer-logo-6.png"
                            alt="alternative"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div id="services" class="cards-1">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h2>Services We Provide</h2>
                <p class="p-heading p-large">
                  We serve small to large food chain companies in all tech
                  related restaurants with high quality services which are
                  presented below
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  {/* <img
                    class="card-image"
                    src="images/services-icon-1.svg"
                    alt="alternative"
                  /> */}
                  <div class="card-body">
                    <h4
                      class="card-title"
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "red",
                      }}
                    >
                      Captain's Application
                    </h4>
                    <p>From Captain To Kitchen, Order Taking Application.</p>
                  </div>
                </div>

                <div class="card">
                  {/* <img
                    class="card-image"
                    src="images/services-icon-2.svg"
                    alt="alternative"
                  /> */}
                  <div class="card-body">
                    <h4
                      class="card-title"
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "red",
                      }}
                    >
                      Table Management
                    </h4>
                    <p>Edit Items, Swap, Merge Tables With One Click.</p>
                  </div>
                </div>

                <div class="card">
                  {/* <img
                    class="card-image"
                    src="images/services-icon-3.svg"
                    alt="alternative"
                  /> */}
                  <div class="card-body">
                    <h4
                      class="card-title"
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "red",
                      }}
                    >
                      Reports Analysis
                    </h4>
                    <p>Multiple Reports For Your Analysis And Management.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  {/* <img
                    class="card-image"
                    src="images/services-icon-1.svg"
                    alt="alternative"
                  /> */}
                  <div class="card-body">
                    <h4
                      class="card-title"
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "red",
                      }}
                    >
                      Admin's Application
                    </h4>
                    <p>For Admin/Owner To Manage And Maintain Restaurant.</p>
                  </div>
                </div>

                <div class="card">
                  {/* <img
                    class="card-image"
                    src="images/services-icon-2.svg"
                    alt="alternative"
                  /> */}
                  <div class="card-body">
                    <h4
                      class="card-title"
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "red",
                      }}
                    >
                      Kiosk
                    </h4>
                    <p>
                      An Touch Friendly Kiosk For Outlet, Cafes, Self-Services
                      Outlets.
                    </p>
                  </div>
                </div>

                <div class="card">
                  {/* <img
                    class="card-image"
                    src="images/services-icon-3.svg"
                    alt="alternative"
                  /> */}
                  <div class="card-body">
                    <h4
                      class="card-title"
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "red",
                      }}
                    >
                      Token Display System
                    </h4>
                    <p>Full Screen View For Token Management In Kiosk.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="basic-2">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="text-container">
                  <h2>Never Miss An Order In Restaurant</h2>

                  <ul class="list-unstyled li-space-lg">
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Table Managements</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Order Managements</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Billing Reports</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">
                        Kitchen Display System App (KDS)
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Touch Billing App (Kiosk)</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">
                        Token Display System App (Kiosk)
                      </div>
                    </li>
                  </ul>
                  <a class="btn-solid-reg popup-with-move-anim" href="#request">
                    Learn More
                  </a>
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="image-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    class="img-fluid"
                    src={require("./images/1.png")}
                    alt="alternative"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="basic-2">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div
                  class="image-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    class="img-fluid"
                    src={require("./images/2.png")}
                    alt="alternative"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="text-container">
                  <h2>Track Your Progress With Our App</h2>
                  <p>
                    We will provide services in all types of food businesses,
                    from a standalone food outlet to a large food chain, manage
                    functions like billing, QR Code Ordering Platform, CRM,
                    Analytics, Centralized Menu Management and more.
                  </p>
                  <ul class="list-unstyled li-space-lg">
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Cloud Based Data Storage</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Business Dashboard</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Reports With Overview</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">
                        Kiosk With Token Display System
                      </div>
                    </li>
                  </ul>
                  {/* <ul class="list-unstyled li-space-lg">
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Table Managements</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Order Managements</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Billing Reports</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">
                        Kitchen Display System App (KDS)
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">Touch Billing App (Kiosk)</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">
                        Token Display System App (Kiosk)
                      </div>
                    </li>
                  </ul> */}
                  <a class="btn-solid-reg popup-with-move-anim" href="#request">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          id="details-lightbox-1"
          class="lightbox-basic zoom-anim-dialog mfp-hide"
        >
          <div class="container">
            <div class="row">
              <button
                title="Close (Esc)"
                type="button"
                class="mfp-close x-button"
              >
                ×
              </button>
              <div class="col-lg-8">
                <div class="image-container">
                  <img
                    class="img-fluid"
                    src="images/details-lightbox-1.svg"
                    alt="alternative"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <h3>Design And Plan</h3>
                <hr />
                <h5>Core feature</h5>
                <p>
                  The emailing module basically will speed up your email
                  marketing operations while offering more subscriber control.
                </p>
                <p>
                  Do you need to build lists for your email campaigns? It just
                  got easier with Evolo.
                </p>
                <ul class="list-unstyled li-space-lg">
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">List building framework</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">Easy database browsing</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">User administration</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">Automate user signup</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">Quick formatting tools</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">Fast email checking</div>
                  </li>
                </ul>
                <a class="btn-solid-reg mfp-close page-scroll" href="#request">
                  REQUEST
                </a>{" "}
                <a
                  class="btn-outline-reg mfp-close as-button"
                  href="#screenshots"
                >
                  BACK
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          id="details-lightbox-2"
          class="lightbox-basic zoom-anim-dialog mfp-hide"
        >
          <div class="container">
            <div class="row">
              <button
                title="Close (Esc)"
                type="button"
                class="mfp-close x-button"
              >
                ×
              </button>
              <div class="col-lg-8">
                <div class="image-container">
                  <img
                    class="img-fluid"
                    src="images/details-lightbox-2.svg"
                    alt="alternative"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <h3>Search To Optimize</h3>
                <hr />
                <h5>Core feature</h5>
                <p>
                  The emailing module basically will speed up your email
                  marketing operations while offering more subscriber control.
                </p>
                <p>
                  Do you need to build lists for your email campaigns? It just
                  got easier with Evolo.
                </p>
                <ul class="list-unstyled li-space-lg">
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">List building framework</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">Easy database browsing</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">User administration</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">Automate user signup</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">Quick formatting tools</div>
                  </li>
                  <li class="media">
                    <i class="fas fa-check"></i>
                    <div class="media-body">Fast email checking</div>
                  </li>
                </ul>
                <a class="btn-solid-reg mfp-close page-scroll" href="#request">
                  REQUEST
                </a>{" "}
                <a
                  class="btn-outline-reg mfp-close as-button"
                  href="#screenshots"
                >
                  BACK
                </a>
              </div>
            </div>
          </div>
        </div> */}

        <div id="pricing" class="cards-2">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h2>Multiple Pricing Options</h2>
                <p class="p-heading p-large">
                  We've prepared pricing plans for all budgets so you can get
                  started right away. They're great for small to large chain
                  restaurants.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title">FREE</div>
                    <div class="card-subtitle">
                      Just to see what can be achieved
                    </div>
                    <hr class="cell-divide-hr" />
                    <div class="price">
                      <span class="currency">₹</span>
                      <span class="value">0</span>
                      <div class="frequency">Trial 28 Days</div>
                      <div class="frequency" style={{ color: "white" }}>
                        S
                      </div>
                    </div>
                    <hr class="cell-divide-hr" />
                    <ul class="list-unstyled li-space-lg">
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">
                          All Restaurant Mangement Features
                        </div>
                      </li>
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">Admin App In Android & iOS</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">Captains App In Android</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">
                          Captains App In Windows Computer
                        </div>
                      </li>
                    </ul>
                    <div class="button-wrapper">
                      <a class="btn-solid-reg page-scroll" href="#request">
                        REQUEST
                      </a>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-body">
                    <div class="card-title">MONTHLY</div>
                    <div class="card-subtitle">
                      Very appropriate for the short term
                    </div>
                    <hr class="cell-divide-hr" />
                    <div class="price">
                      <span class="currency">₹</span>
                      <span class="value">550</span>
                      <div class="frequency" style={{ color: "white" }}>
                        S
                      </div>
                      <div class="frequency" style={{ color: "white" }}>
                        S
                      </div>
                    </div>
                    <hr class="cell-divide-hr" />
                    <ul class="list-unstyled li-space-lg">
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">
                          All Restaurant Mangement Features
                        </div>
                      </li>
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">Admin App In Android & iOS</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">Captains App In Android</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">
                          Captains App In Windows Computer
                        </div>
                      </li>
                    </ul>
                    <div class="button-wrapper">
                      <a class="btn-solid-reg page-scroll" href="#request">
                        Purchase Now
                      </a>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="label">
                    <p class="best-value">Best Value</p>
                  </div>
                  <div class="card-body">
                    <div class="card-title">YEARLY</div>
                    <div class="card-subtitle">Must have for long term</div>
                    <hr class="cell-divide-hr" />
                    <div class="price">
                      <span class="currency">₹</span>
                      <span class="value">5000</span>
                      <div class="frequency">Save 25%</div>
                      <div class="frequency" style={{ color: "white" }}>
                        S
                      </div>
                    </div>
                    <hr class="cell-divide-hr" />
                    <ul class="list-unstyled li-space-lg">
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">
                          All Restaurant Mangement Features
                        </div>
                      </li>
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">Admin App In Android & iOS</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">Captains App In Android</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-check"></i>
                        <div class="media-body">
                          Captains App In Windows Computer
                        </div>
                      </li>
                    </ul>
                    <div class="button-wrapper">
                      <a class="btn-solid-reg page-scroll" href="#request">
                        Purchase Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="request" class="form-1">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="text-container">
                  <h2>Fill The Following Form To Request A Meeting</h2>
                  <p>
                    Postick is one of the easiest and feature packed restaurant
                    management apps in the market. Discover what it can do for
                    your business organizations right away.
                  </p>
                  {/* <ul class="list-unstyled li-space-lg">
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">
                        <strong class="blue">Automate your marketing</strong>{" "}
                        activities and get results today
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">
                        <strong class="blue">Interact with all your</strong>{" "}
                        targeted customers at a personal level
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">
                        <strong class="blue">Convince them to buy</strong> your
                        company's awesome products
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-check"></i>
                      <div class="media-body">
                        <strong class="blue">Save precious time</strong> and
                        invest it where you need it the most
                      </div>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-container">
                  {this.state.hide == false ? (
                    <form
                      // id="requestForm"
                      // data-toggle="validator"
                      data-focus="false"
                    >
                      <div class="form-group">
                        {/* <label for="rname">Restaurant Name</label> */}

                        <input
                          type="text"
                          class="form-control-input"
                          placeholder="Restaurant Name"
                          id="remail"
                          style={{
                            paddingTop: 0,
                            padding: 10,
                            paddingLeft: 20,
                          }}
                          name="remail"
                          required
                          onChange={(e) =>
                            this.setState({
                              restaurant_name: e.target.value,
                              err_restaurant: "",
                            })
                          }
                        />

                        <div class="help-block with-errors">
                          {this.state.err_restaurant}
                        </div>
                      </div>
                      <div class="form-group">
                        <input
                          type="text"
                          placeholder="Your City"
                          class="form-control-input"
                          required
                          style={{
                            paddingTop: 0,
                            padding: 10,
                            paddingLeft: 20,
                          }}
                          onChange={(e) =>
                            this.setState({
                              city: e.target.value,
                              err_city: "",
                            })
                          }
                        />
                        {/* <label class="label-control" for="rname">
                          Your City
                        </label> */}
                        <div class="help-block with-errors">
                          {this.state.err_city}
                        </div>
                      </div>
                      <div class="form-group">
                        <input
                          type="number"
                          class="form-control-input"
                          placeholder="Mobile Number"
                          required
                          style={{
                            paddingTop: 0,
                            padding: 10,
                            paddingLeft: 20,
                          }}
                          value={this.state.mobile}
                          onChange={(e) =>
                            this.setState({
                              mobile: e.target.value,
                              err_mobile: "",
                            })
                          }
                        />
                        {/* <label class="label-control" for="remail">
                          Mobile No.
                        </label> */}
                        <div class="help-block with-errors">
                          {this.state.err_mobile}
                        </div>
                      </div>
                      <div class="form-group">
                        <button
                          onClick={() => this.request_demo()}
                          type="button"
                          class="form-control-submit-button"
                        >
                          Submit
                        </button>
                      </div>
                      <div class="form-message">
                        <div
                          id="rmsgSubmit"
                          class="h3 text-center hidden"
                        ></div>
                      </div>
                    </form>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div class="image-container">
                        {this.state.error == false ? (
                          <img
                            class="img-fluid"
                            src={require("./images/check-circle.gif")}
                            alt="alternative"
                            style={{
                              width: 200,
                              height: 200,
                            }}
                          />
                        ) : (
                          <img
                            class="img-fluid"
                            src={require("./images/cross.gif")}
                            alt="alternative"
                            style={{
                              width: 200,
                              height: 200,
                            }}
                          />
                        )}
                      </div>
                      <div class="form-message">
                        <div id="rmsgSubmit" class="h3 text-center hidden">
                          {this.state.s_msg}
                          <br />
                          <br />
                          {this.state.s_msg_2}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="basic-3">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h2>Check Out The Video</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-container">
                  <div class="video-wrapper">
                    <a
                      class="popup-youtube"
                      href="https://www.youtube.com/watch?v=fLCjQJCekTs"
                      data-effect="fadeIn"
                    >
                      <img
                        class="img-fluid"
                        src="images/video-frame.svg"
                        alt="alternative"
                      />
                      <span class="video-play-button">
                        <span></span>
                      </span>
                    </a>
                  </div>
                </div>

                <p>
                  This video will show you a case study for one of our{" "}
                  <strong>Major Customers</strong> and will help you understand
                  why your startup needs Evolo in this highly competitive market
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* 
        <div class="slider-2">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="image-container">
                  <img
                    class="img-fluid"
                    src="images/testimonials-2-men-talking.svg"
                    alt="alternative"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <h2>Testimonials</h2>

                <div class="slider-container">
                  <div class="swiper-container card-slider">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div class="card">
                          <img
                            class="card-image"
                            src="images/testimonial-1.svg"
                            alt="alternative"
                          />
                          <div class="card-body">
                            <p class="testimonial-text">
                              I just finished my trial period and was so amazed
                              with the support and results that I purchased
                              Evolo right away at the special price.
                            </p>
                            <p class="testimonial-author">
                              Jude Thorn - Designer
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="swiper-slide">
                        <div class="card">
                          <img
                            class="card-image"
                            src="images/testimonial-2.svg"
                            alt="alternative"
                          />
                          <div class="card-body">
                            <p class="testimonial-text">
                              Evolo has always helped or startup to position
                              itself in the highly competitive market of mobile
                              applications. You will not regret using it!
                            </p>
                            <p class="testimonial-author">
                              Marsha Singer - Developer
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="swiper-slide">
                        <div class="card">
                          <img
                            class="card-image"
                            src="images/testimonial-3.svg"
                            alt="alternative"
                          />
                          <div class="card-body">
                            <p class="testimonial-text">
                              Love their services and was so amazed with the
                              support and results that I purchased Evolo for two
                              years in a row. They are awesome.
                            </p>
                            <p class="testimonial-author">
                              Roy Smith - Marketer
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div id="about" class="basic-4">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h2>About The Team</h2>
                <p class="p-heading p-large">
                  Meat our team of specialized marketers and business developers
                  which will help you research new products and launch them in
                  new emerging markets
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="team-member">
                  <div class="image-wrapper">
                    <img
                      class="img-fluid"
                      src="images/team-member-1.svg"
                      alt="alternative"
                    />
                  </div>
                  <p class="p-large">
                    <strong>Lacy Whitelong</strong>
                  </p>
                  <p class="job-title">Business Developer</p>
                  <span class="social-icons">
                    <span class="fa-stack">
                      <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x facebook"></i>
                        <i class="fab fa-facebook-f fa-stack-1x"></i>
                      </a>
                    </span>
                    <span class="fa-stack">
                      <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x twitter"></i>
                        <i class="fab fa-twitter fa-stack-1x"></i>
                      </a>
                    </span>
                  </span>
                </div>

                <div class="team-member">
                  <div class="image-wrapper">
                    <img
                      class="img-fluid"
                      src="images/team-member-2.svg"
                      alt="alternative"
                    />
                  </div>
                  <p class="p-large">
                    <strong>Chris Brown</strong>
                  </p>
                  <p class="job-title">Online Marketer</p>
                  <span class="social-icons">
                    <span class="fa-stack">
                      <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x facebook"></i>
                        <i class="fab fa-facebook-f fa-stack-1x"></i>
                      </a>
                    </span>
                    <span class="fa-stack">
                      <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x twitter"></i>
                        <i class="fab fa-twitter fa-stack-1x"></i>
                      </a>
                    </span>
                  </span>
                </div>

                <div class="team-member">
                  <div class="image-wrapper">
                    <img
                      class="img-fluid"
                      src="images/team-member-3.svg"
                      alt="alternative"
                    />
                  </div>
                  <p class="p-large">
                    <strong>Sheila Zimerman</strong>
                  </p>
                  <p class="job-title">Software Engineer</p>
                  <span class="social-icons">
                    <span class="fa-stack">
                      <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x facebook"></i>
                        <i class="fab fa-facebook-f fa-stack-1x"></i>
                      </a>
                    </span>
                    <span class="fa-stack">
                      <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x twitter"></i>
                        <i class="fab fa-twitter fa-stack-1x"></i>
                      </a>
                    </span>
                  </span>
                </div>

                <div class="team-member">
                  <div class="image-wrapper">
                    <img
                      class="img-fluid"
                      src="images/team-member-4.svg"
                      alt="alternative"
                    />
                  </div>
                  <p class="p-large">
                    <strong>Mary Villalonga</strong>
                  </p>
                  <p class="job-title">Product Manager</p>
                  <span class="social-icons">
                    <span class="fa-stack">
                      <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x facebook"></i>
                        <i class="fab fa-facebook-f fa-stack-1x"></i>
                      </a>
                    </span>
                    <span class="fa-stack">
                      <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x twitter"></i>
                        <i class="fab fa-twitter fa-stack-1x"></i>
                      </a>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div id="contact" class="form-2">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h2>Contact Information</h2>
                <ul class="list-unstyled li-space-lg">
                  <li class="address">
                    Don't hesitate to give us a call or send us a contact form
                    message
                  </li>
                  <li>
                    <i class="fas fa-map-marker-alt"></i>Dhatiya Faliya, Nr.
                    Lugda Bazaar, Anam Ring Road, Bhuj-Kutch, 370001
                  </li>
                  <li>
                    <i class="fas fa-phone"></i>
                    <a class="turquoise" href="tel:+918128980289">
                      +91 81289 80289
                    </a>
                  </li>
                  <li>
                    <i class="fas fa-envelope"></i>
                    <a class="turquoise" href="mailto:office@evolo.com">
                      {/* office@evolo.com */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="map-responsive">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14663.71283719932!2d69.65686108021032!3d23.245699357446718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950e1a0117767b1%3A0x31c30e4cc118c6f2!2sShree%20Ram%20Technologies!5e0!3m2!1sen!2sin!4v1649169714818!5m2!1sen!2sin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="image-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    class="img-fluid"
                    src={require("./images/Contactus.gif")}
                    alt="alternative"
                    style={{ width: 350, height: 350 }}
                  />
                </div>
                {/* <form
                  id="contactForm"
                  data-toggle="validator"
                  data-focus="false"
                >
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control-input"
                      id="cname"
                      required
                    />
                    <label class="label-control" for="cname">
                      Name
                    </label>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control-input"
                      id="cemail"
                      required
                    />
                    <label class="label-control" for="cemail">
                      Email
                    </label>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group">
                    <textarea
                      class="form-control-textarea"
                      id="cmessage"
                      required
                    ></textarea>
                    <label class="label-control" for="cmessage">
                      Your message
                    </label>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group checkbox">
                    <input
                      type="checkbox"
                      id="cterms"
                      value="Agreed-to-Terms"
                      required
                    />
                    I have read and agree with Postcik's stated{" "}
                    <a href="privacy-policy.html">Privacy Policy</a> and{" "}
                    <a href="terms-conditions.html">Terms Conditions</a>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group">
                    <button type="submit" class="form-control-submit-button">
                      SUBMIT MESSAGE
                    </button>
                  </div>
                  <div class="form-message">
                    <div id="cmsgSubmit" class="h3 text-center hidden"></div>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="footer-col">
                  <h4>About Postick Pos</h4>
                  <p>
                    We're passionate about offering some of the best management
                    services for all types of restaurants
                  </p>
                </div>
              </div>
              <div class="col-md-4" id="download">
                <div class="footer-col middle">
                  <h4>Download Links</h4>
                  <ul class="list-unstyled li-space-lg">
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        For Admins{" "}
                        <a
                          class="turquoise"
                          target="_blank"
                          href={this.state.admin_ios}
                        >
                          iOS
                        </a>
                        {" & "}
                        <a
                          class="turquoise"
                          target="_blank"
                          href={this.state.admin_android}
                        >
                          Android
                        </a>
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        For Captains{" "}
                        <a
                          class="turquoise"
                          target="_blank"
                          href={this.state.captain_android}
                        >
                          Android
                        </a>
                        {" & "}
                        <a
                          class="turquoise"
                          target="_blank"
                          href={this.state.captain_win}
                        >
                          Windows
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-col last">
                  <h4>Social Media</h4>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/Postick-110506818235650"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-facebook-f fa-stack-1x"></i>
                    </a>
                  </span>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/pos.tick/"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-instagram fa-stack-1x"></i>
                    </a>
                  </span>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/postick-pos-70649a234/"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-linkedin-in fa-stack-1x"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <p class="p-small">
                  Copyright © 2020{" "}
                  <a href="http://www.postick.co.in">Postick</a> - All rights
                  reserved. <a href="/terms">{"Terms & Conditions"}</a>
                  {" | "}
                  <a href="/policy">{"Privacy Policy"}</a> {" | "}
                  <a href="/policy">{"Refund Policy"}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
