import React, { Component } from "react";
import BackendService from "./Backend/backend-service";

const backendService = new BackendService();

export default class Policy extends Component {
  state = {
    full_name: "",
    restaurant_name: "",
    city: "",
    mobile: "",
    interested_in: "",
    s_msg: "",
    hide: false,
    error: false,
    err_restaurant: "",
    err_city: "",
    err_mobile: "",
    admin_ios: "#",
    admin_android: "#",
    captain_android: "#",
    captain_win: "#",
  };

  componentDidMount() {
    this.load_download_links();
  }

  load_download_links() {
    backendService.load_download_links().then((r) => {
      if (r.error == "False") {
        this.setState({
          admin_ios: r.admin_ios,
          admin_android: r.admin_android,
          captain_android: r.captain_android,
          captain_win: r.captain_win,
        });
      }
    });
  }

  request_demo() {
    if (this.state.restaurant_name == "") {
      this.setState({
        err_restaurant: "Please Fill This Field",
        err_city: "",
        err_mobile: "",
      });
    } else if (this.state.city == "") {
      this.setState({
        err_city: "Please Fill This Field",
        err_restaurant: "",
        err_mobile: "",
      });
    } else if (this.state.mobile == "") {
      this.setState({
        err_mobile: "Please Fill This Field",
        err_city: "",
        err_restaurant: "",
      });
    } else if (this.state.mobile.length != 10) {
      this.setState({
        err_mobile: "Please Fill Mobile No. With 10 Digit",
        err_city: "",
        err_restaurant: "",
      });
    } else {
      var data = {
        restaurant_name: this.state.restaurant_name,
        city: this.state.city,
        mobile: this.state.mobile,
      };

      backendService.request_demo(data).then((r) => {
        if (r.error == "False") {
          this.setState({
            hide: true,
            error: false,
            s_msg: "Thank You For Submit Your Request For Demo.",
            s_msg_2: " We Will Reach You Within 24 Hour.",
            full_name: "",
            restaurant_name: "",
            city: "",
            mobile: "",
            interested_in: "",
          });
        } else {
          this.setState({
            hide: true,
            error: true,
            s_msg: "Something Went Wrong.",
            s_msg_2: " Please Try Again",
          });
        }
      });
    }
  }
  render() {
    return (
      <div>
        <div class="spinner-wrapper">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>

        <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
          <a class="navbar-brand logo-image" href="index.html">
            <img src="images/postick.png" alt="alternative" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-awesome fas fa-bars"></span>
            <span class="navbar-toggler-awesome fas fa-times"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/home">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/terms">
                  Terms & Conditions
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/policy">
                  Privacy Policy
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/refund">
                  Refund Policy
                </a>
              </li>
            </ul>
            <span class="nav-item social-icons">
              <span class="fa-stack">
                <a
                  target="_blank"
                  href="https://www.facebook.com/Postick-110506818235650"
                >
                  <i class="fas fa-circle fa-stack-2x facebook"></i>
                  <i class="fab fa-facebook-f fa-stack-1x"></i>
                </a>
              </span>
              <span class="fa-stack">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/postick-pos-70649a234/"
                >
                  <i class="fas fa-circle fa-stack-2x linkedin"></i>
                  <i class="fab fa-linkedin fa-stack-1x"></i>
                </a>
              </span>
            </span>
          </div>
        </nav>

        <header id="header" class="header">
          <div class="header-content">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="text-container">
                    <h1>
                      <span class="turquoise">POSTICK</span> Restaurant
                      Management Solutions
                    </h1>
                    <p class="p-large">
                      <h1 class="turquoise">Privacy Policy</h1>
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="image-container">
                    <img
                      class="img-fluid"
                      src={require("./images/Waiters-bro.png")}
                      alt="alternative"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div class="ex-basic-1" style={{ backgroundColor: "#fde5ed" }}>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumbs">
                  <a href="/home">Home</a>
                  <i class="fa fa-angle-double-right"></i>
                  <span>Privacy Policy</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ex-basic-2">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 offset-lg-1">
                <div class="text-container">
                  <h3>Privacy Policy for Postick</h3>
                  <p>
                    We respect and are committed towards protecting your
                    privacy. Publishing, selling or renting any personal data or
                    information to any third party, without your consent, is
                    against our ethics.
                  </p>
                  <p>
                    The privacy practices of this statement apply to our
                    services available under the{" "}
                    <a href="http://postick.co.in/">postick.co.in</a> and
                    subdomains of the Site. By visiting this Site you agree to
                    be bound by the terms and conditions of this privacy policy.
                    If you do not agree, please do not use or access our site.
                  </p>
                  <p>
                    This privacy policy does not apply to sites maintained by
                    other companies or organizations to which we link and we are
                    not responsible for any personal information you submit to
                    third parties via our website. Please ensure that you read
                    the privacy policy of such other companies or organizations
                    before submitting your details.
                  </p>
                  <p>
                    This privacy policy describes the information, as part of
                    the normal operation of our services, we collect from you
                    and what may happen to that information. This policy is
                    inter alia formulated and displayed, to inform you about our
                    information collection/retention policies and practices so
                    that you can make an informed decision, in relation to the
                    sharing of your personal information with us. By accepting
                    the privacy policy and the user agreement or the use of the
                    site in general, you give your consent to our use and
                    disclosure of your personal information in accordance with
                    this privacy policy. This Privacy Policy is incorporated
                    into and subject to the terms of the User Agreement. This
                    privacy policy is effective upon acceptance of access by you
                    to the site.
                  </p>
                  <p>
                    Postick POS also automatically collects and receives certain
                    information from your computer or mobile device, including
                    the activities you perform on our Website, the Platforms,
                    and the Applications, the type of hardware and software you
                    are using (for example, your operating system or browser),
                    and information obtained from cookies. For example, each
                    time you visit the Website or otherwise use the Services, we
                    automatically collect your IP address, browser and device
                    type, access times, the web page from which you came, the
                    regions from which you navigate the web page, and the web
                    page(s) you access (as applicable).
                  </p>
                  <p>
                    When you first register for a Postick POS account, and when
                    you use the Services, we collect some{" "}
                    <a class="turquoise" href="#your-link">
                      Personal Information
                    </a>{" "}
                    about you such as:
                  </p>
                  <div class="row">
                    <div class="col-md-6">
                      <ul class="list-unstyled li-space-lg indent">
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            The geographic area where you use your computer and
                            mobile devices
                          </div>
                        </li>
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            Your full name, username, and email address and
                            other contact details
                          </div>
                        </li>
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            A unique Postick POS user ID (an alphanumeric
                            string) which is assigned to you upon registration
                          </div>
                        </li>
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            Other optional information as part of your account
                            profile
                          </div>
                        </li>
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            Your IP Address and, when applicable, timestamp
                            related to your consent and confirmation of consent
                          </div>
                        </li>
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            Other information submitted by you or your
                            organizational representatives via various methods
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="col-md-6">
                      <ul class="list-unstyled li-space-lg indent">
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            Your billing address and any necessary other
                            information to complete any financial transaction,
                            and when making purchases through the Services, we
                            may also collect your credit card or PayPal or any
                            other type of payment method information
                          </div>
                        </li>
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            User generated content (such as messages, posts,
                            comments, pages, profiles, images, feeds or
                            communications exchanged on the Supported Platforms)
                          </div>
                        </li>
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            Images or other files that you may publish via our
                            Services
                          </div>
                        </li>
                        <li class="media">
                          <i class="fas fa-square"></i>
                          <div class="media-body">
                            Information (such as messages, posts, comments,
                            pages, profiles, images) we may receive relating to
                            communications you send us, such as queries or
                            comments concerning
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="text-container">
                  <h3>Privacy Guarantee</h3>
                  <p>
                    We agree that we will not sell or rent your personal
                    information to third parties for their marketing purposes
                    without your explicit consent. From time to time, we may
                    reveal general statistical information about our Site and
                    visitors, such as number of visitors, number and type of
                    goods and services purchased, etc. Only those of our
                    employees who need access to your information in order to
                    perform their duties, are allowed such access. Any employee
                    who violates our privacy and/or security policies is
                    subjected to disciplinary action, including possible
                    termination and civil and/or criminal prosecution.
                  </p>
                </div>
                <div class="text-container">
                  <h3>Information We Collect</h3>
                  <p>
                    The Personal Information is used for two general purposes:
                    to process your order, and to provide you with the best
                    possible services. Unless otherwise stated explicitly, this
                    policy applies to personal information as disclosed on any
                    of the media.
                  </p>
                  <p>
                    In furtherance of the confidentiality with which we treat
                    Personal Information, we have put in place appropriate
                    physical, electronic, and managerial procedures to safeguard
                    and secure the information we collect online. We use data
                    collection devices such as "cookies" on certain pages of the
                    Site to help and analyze our web page flow, measure
                    promotional effectiveness, and promote trust and safety.
                    "Cookies" are small files placed on your hard drive that
                    assist us in providing our services. We offer certain
                    features that are only available through the use of a
                    "cookie". Cookies can also help us provide information that
                    is targeted to your interests. Most cookies are "session
                    cookies," which means that they are automatically deleted
                    from your hard drive at the end of a session. You are always
                    free to decline our cookies if your browser permits.
                    Additionally, you may encounter "cookies" or other similar
                    devices on certain pages of the Site that are placed by
                    third parties. For example, if you view a web page created
                    by a user, there may be a "cookie" placed within that web
                    page. We do not control the use of cookies by third parties.
                  </p>
                  <p>
                    You acknowledge that you are disclosing Personal Information
                    voluntarily. We identify and use your IP address to help
                    diagnose problems with our server, and to administer our
                    Website. Your IP address is also used to help identify you
                    and to gather broad demographic information.
                  </p>
                </div>
                <div class="text-container">
                  <h3>COOKIE POLICY</h3>
                  <p>
                    Postick.co.in operates a strict privacy policy and we are
                    committed to being transparent about how we use cookies on
                    our website.
                  </p>
                  <p>Why are cookies important?</p>
                  <p>
                    Cookies help you make your online experience more efficient
                    and relevant to your interests. For instance, they are used
                    to remember your preferences on sites you visit often, to
                    remember your user ID and the contents of your shopping
                    baskets, and to help you navigate between pages efficiently.
                  </p>
                  <p>What is a Cookie?</p>
                  <p>
                    A cookie is a small file, or files on your computer, phone,
                    or other device with a browser to save snippets of text for
                    reference by the website you are visiting. All cookies have
                    expiration dates in them that determine how long they stay
                    in your browser:
                  </p>
                  <p>
                    Session cookies - these are temporary cookies that expire
                    (and are automatically erased) whenever you close your
                    browser.
                  </p>
                  <p>
                    Persistent cookies - these usually have an expiration date
                    and so stay in your browser until they expire, or until you
                    manually delete them. For example we use persistent cookies
                    to better understand usage patterns so that we can improve
                    the site for our customers.
                  </p>
                  <p>Cookies are grouped into the following categories:</p>
                  <p>
                    Essential - these are cookies that are required for the
                    regular operation of our websites.
                  </p>
                  <p>
                    Functional - these remember your preferences, and are
                    intended to make your experience on our websites better for
                    you.
                  </p>
                  <p>
                    Analytics – these cookies are used for performance
                    measurement to understand things including how many people
                    visit our websites, how they navigate our sites, and what
                    content is popular. This allows us to improve your
                    experience with us. Additionally, you can see how Google
                    Analytics (one of our analytics tools) uses cookie
                    information when you use our partners' sites by visiting
                    www.google.com/policies/privacy/partners, or any other URL
                    Google may provide from time to time Advertising - these
                    cookies enable us and our advertising partners to serve you
                    with relevant advertisements that we think will interest
                    you. You might see these advertisements on our sites on
                    other sites you visit. These cookies record your visit to
                    our website and the content you interact with. They may be
                    placed by us, or by advertising partners with our
                    permission.
                  </p>
                  <p>
                    To ensure compliance with our policies, we restrict the use
                    of third-party cookies to trusted partners.
                  </p>
                  <p>
                    We also use third party information from third party sources
                    to enable us deliver advertising. These sources are
                    validated by Third party, and not by us.
                  </p>
                  <p>
                    To delete cookies from your browser, please note the
                    following simple steps. The following steps are indicative
                    for Google chrome and might vary of the different browsers
                    that you may use. Please refer Settings on your browser for
                    further details.
                  </p>
                  <p>
                    To ensure compliance with our policies, we restrict the use
                    of third-party cookies to trusted partners.
                  </p>
                  <p>
                    You may also change other setting related to Privacy and
                    Security under the same section.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="container">
            <div class="row" style={{ justifyContent: "space-between" }}>
              <div class="col-md-4">
                <div class="footer-col">
                  <h4>About Postick Pos</h4>
                  <p>
                    We're passionate about offering some of the best management
                    services for all types of restaurants
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-col last">
                  <h4>Social Media</h4>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/Postick-110506818235650"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-facebook-f fa-stack-1x"></i>
                    </a>
                  </span>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/pos.tick/"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-instagram fa-stack-1x"></i>
                    </a>
                  </span>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/postick-pos-70649a234/"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-linkedin-in fa-stack-1x"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <p class="p-small">
                  Copyright © 2020{" "}
                  <a href="http://www.postick.co.in">Postick</a> - All rights
                  reserved. <a href="/terms">{"Terms & Conditions"}</a>
                  {" | "}
                  <a href="/policy">{"Privacy Policy"}</a> {" | "}
                  <a href="/policy">{"Refund Policy"}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
