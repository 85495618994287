// The following is a sample implementation of a backend service using Progress Kinvey (https://www.progress.com/kinvey).
// Feel free to swap in your own service / APIs / etc here for your own apps.
const api_link = "http://apis.postick.co.in/admin_apir.php";
const accesskey = "123";

export default class BackendService {
  request_demo(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        request_demo: "1",
        access_key: accesskey,
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_download_links(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        load_download_links: "1",
        access_key: accesskey,
        data: data,
      }),
    }).then((response) => response.json());
  }
}
