import React, { Component } from "react";

export default class TermsAndCondition extends Component {
  render() {
    return (
      <div>
        <div class="spinner-wrapper">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>

        <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
          <a class="navbar-brand logo-image" href="index.html">
            <img src="images/postick.png" alt="alternative" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-awesome fas fa-bars"></span>
            <span class="navbar-toggler-awesome fas fa-times"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/home">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/terms">
                  Terms & Conditions
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/policy">
                  Privacy Policy
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/refund">
                  Refund Policy
                </a>
              </li>
            </ul>
            <span class="nav-item social-icons">
              <span class="fa-stack">
                <a
                  target="_blank"
                  href="https://www.facebook.com/Postick-110506818235650"
                >
                  <i class="fas fa-circle fa-stack-2x facebook"></i>
                  <i class="fab fa-facebook-f fa-stack-1x"></i>
                </a>
              </span>
              <span class="fa-stack">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/postick-pos-70649a234/"
                >
                  <i class="fas fa-circle fa-stack-2x linkedin"></i>
                  <i class="fab fa-linkedin fa-stack-1x"></i>
                </a>
              </span>
            </span>
          </div>
        </nav>

        <header id="header" class="header">
          <div class="header-content">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="text-container">
                    <h1>
                      <span class="turquoise">POSTICK</span> Restaurant
                      Management Solutions
                    </h1>
                    <p class="p-large">
                      <h1 class="turquoise">TERMS & CONDITIONS</h1>
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="image-container">
                    <img
                      class="img-fluid"
                      src={require("./images/Waiters-bro.png")}
                      alt="alternative"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div class="ex-basic-1" style={{ backgroundColor: "#fde5ed" }}>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumbs">
                  <a href="/home">Home</a>
                  <i class="fa fa-angle-double-right"></i>
                  <span>TERMS & CONDITIONS</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ex-basic-2">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 offset-lg-1">
                <div class="text-container">
                  <h3>TERMS & CONDITIONS</h3>
                  <p>
                    Thank you for accessing/shopping at postick.co.in. This site
                    is owned by Shree Ram Technologies (hereinafter referred to
                    as postick.co.in). By accessing, shopping on this site, you
                    indicate your unconditional acceptance of these terms &
                    conditions. We reserve this right, in our sole discretion,
                    to update or revise these terms & conditions. Continued use
                    of the site following the posting of any changes to the
                    ‘terms & conditions’, constitutes your acceptance of those
                    changes. At postick.co.in, we try our best to create a space
                    where you can explore and shop for all your favorite things
                    in a safe and secure environment. All products and
                    information displayed on postick.co.in constitutes an
                    "invitation to offer". postick.co.in reserves the right to
                    accept or reject your offer. Your order for purchase,
                    constitutes your "offer" which shall be subject to the terms
                    and conditions as listed below.
                  </p>
                  <h6>1. Eligibility to use our site</h6>
                  <p>
                    Use of the Site is available only to persons who can legally
                    enter into contracts under applicable laws. Persons who are
                    "incompetent to contract", within the meaning of the Indian
                    Contract Act, 1872 including un-discharged insolvents etc.
                    are not eligible to use the Site. Postick.co.in reserves the
                    right to terminate your access to the Site if it discovers
                    that you are under the age of 18 years or suffers from any
                    other disability, as recognized under Indian Contract Act,
                    1872.
                  </p>
                  <h6>2. Membership</h6>
                  <p>
                    Although it's not essential to have an account to shop with
                    Postick.co.in , you can shop as a guest. As a member, you
                    agree to provide true, accurate, current, and complete
                    information about yourself as prompted by the site's
                    registration form. Registration where prohibited under any
                    law shall be void. Postick.co.in reserves the right to
                    revoke or terminate your registration for any reason at any
                    time, without notice.
                  </p>
                  <h6>3. Electronic Communications</h6>
                  <p>
                    When you use the site or send emails or other data,
                    information or communicate to us, you agree and understand
                    that you are communicating with us electronically and give
                    your consent to receive communications electronically from
                    us periodically, when required.
                  </p>
                  <h6>4. Reviews, Feedback, Submissions</h6>
                  <p>
                    All reviews, comments, feedback, postcards, suggestions,
                    ideas, and other submissions disclosed, submitted or offered
                    to Postick.co.in directly or otherwise disclosed, submitted
                    or offered in connection with your use of this Site
                    (collectively referred to "Comments") will remain
                    Postick.co.in property. Such disclosure, submission or offer
                    of any comments shall constitute an assignment to
                    Postick.co.in of all worldwide rights, titles and interests
                    in all copyrights and other intellectual properties in the
                    comments,thus, it exclusively owns all such rights, titles
                    and interests and shall not be limited in any way in its
                    use, commercial or otherwise. Postick.co.in will be entitled
                    to use, reproduce, disclose, modify, adapt, create
                    derivative works from, publish, display and distribute any
                    comments you submit for any purpose whatsoever, without
                    restriction and without compensating you in any way.
                    Postick.co.in is and shall be under no obligation (1) to
                    maintain any Comments in confidence; or (2) to pay you any
                    compensation for any Comments; or (3) to respond to any
                    Comments. You agree that any comments submitted by you to
                    the Site will not violate this policy or any right of any
                    third party, including copyright, trademark, privacy or
                    other personal or proprietary right(s), and will not cause
                    injury to any person or entity. You further agree that no
                    comments submitted by you to the site will be libelous or
                    otherwise unlawful, threatening, abusive or obscene
                    material, or contain software viruses, political
                    campaigning, commercial solicitation, chain letters, mass
                    mailings or any form of "spam".
                  </p>
                  <p>
                    Postick.co.in does not regularly review posted comments, but
                    does reserve the right (but not the obligation) to monitor
                    and edit or remove any comment submitted to the Site. You
                    grant Postick.co.in the right to use the name that you
                    submit in connection with any of the posted comments. You
                    agree not to use a false email address, impersonate any
                    person or entity, or otherwise mislead as to the origin of
                    any Comments you submit. You are and shall remain solely
                    responsible for the content of any comments you make and you
                    agree to indemnify Postick.co.in and its affiliates for all
                    claims resulting from any Comments you submit, we take no
                    responsibility and assume no liability for any comments
                    submitted by you or any third party.
                  </p>
                  <h6>
                    5. Accuracy of Content/ Information of Products on the Web
                    Site
                  </h6>
                  <p>
                    While Postick.co.in strives to provide accurate product and
                    pricing information, typographical errors may occur. In the
                    event that a product is listed at an incorrect price or with
                    incorrect information due to an error in pricing or product
                    information, Postick.co.in shall have the right, on our sole
                    discretion, to modify the price of the products, or
                    information of the products or to refuse or cancel any
                    orders placed for that product, unless the product has
                    already been dispatched. In the event.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="container">
            <div class="row" style={{ justifyContent: "space-between" }}>
              <div class="col-md-4">
                <div class="footer-col">
                  <h4>About Postick Pos</h4>
                  <p>
                    We're passionate about offering some of the best management
                    services for all types of restaurants
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-col last">
                  <h4>Social Media</h4>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/Postick-110506818235650"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-facebook-f fa-stack-1x"></i>
                    </a>
                  </span>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/pos.tick/"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-instagram fa-stack-1x"></i>
                    </a>
                  </span>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/postick-pos-70649a234/"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-linkedin-in fa-stack-1x"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <p class="p-small">
                  Copyright © 2020{" "}
                  <a href="http://www.postick.co.in">Postick</a> - All rights
                  reserved. <a href="/terms">{"Terms & Conditions"}</a>
                  {" | "}
                  <a href="/policy">{"Privacy Policy"}</a> {" | "}
                  <a href="/policy">{"Refund Policy"}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
