import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Policy from "./Components/Policy";
import Refund from "./Components/Refund";
import TermsAndCondition from "./Components/TermsAndCondition";

export default class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/terms" element={<TermsAndCondition />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
