import React, { Component } from "react";

export default class Refund extends Component {
  render() {
    return (
      <div>
        <div class="spinner-wrapper">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>

        <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
          <a class="navbar-brand logo-image" href="index.html">
            <img src="images/postick.png" alt="alternative" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-awesome fas fa-bars"></span>
            <span class="navbar-toggler-awesome fas fa-times"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/home">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/terms">
                  Terms & Conditions
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/policy">
                  Privacy Policy
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link page-scroll" href="/refund">
                  Refund Policy
                </a>
              </li>
            </ul>
            <span class="nav-item social-icons">
              <span class="fa-stack">
                <a
                  target="_blank"
                  href="https://www.facebook.com/Postick-110506818235650"
                >
                  <i class="fas fa-circle fa-stack-2x facebook"></i>
                  <i class="fab fa-facebook-f fa-stack-1x"></i>
                </a>
              </span>
              <span class="fa-stack">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/postick-pos-70649a234/"
                >
                  <i class="fas fa-circle fa-stack-2x linkedin"></i>
                  <i class="fab fa-linkedin fa-stack-1x"></i>
                </a>
              </span>
            </span>
          </div>
        </nav>

        <header id="header" class="header">
          <div class="header-content">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="text-container">
                    <h1>
                      <span class="turquoise">POSTICK</span> Restaurant
                      Management Solutions
                    </h1>
                    <p class="p-large">
                      <h1 class="turquoise">REFUND POLICY</h1>
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="image-container">
                    <img
                      class="img-fluid"
                      src={require("./images/Waiters-bro.png")}
                      alt="alternative"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div class="ex-basic-1" style={{ backgroundColor: "#fde5ed" }}>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumbs">
                  <a href="/home">Home</a>
                  <i class="fa fa-angle-double-right"></i>
                  <span>REFUND POLICY</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ex-basic-2">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 offset-lg-1">
                <div class="text-container">
                  <h3>RETURN AND REFUND POLICY</h3>
                  <p>
                    We, Shree Ram Technology ("We", "Us", "Our", “Company”), is
                    in the business of providing a cloud-based point of sales
                    software application under the brand name “Postick” for
                    restaurants together with optionally integrated services
                    such as mobile apps, inventory management, deals and
                    discounts, ordering , analytics apps, etc. using a
                    programmable API architecture. The Company through such
                    point of sale software application and websites (together
                    referred to as “Postick”) also provides end to end
                    integration of services for restaurant management to various
                    restaurants and food outlets. The Company has created and
                    owns the Postick Platforms and licensed the use of the same
                    by way of various subscription model for a Subscription Fee.
                  </p>
                  <h6>Fees</h6>
                  <p>
                    You are required to pay subscription fee as per the Postick
                    Subscription plan subscribed by you (“Subscription Fee”).
                    The Subscription Fee for Postick Subscription as stated in
                    various subscription plans is non-refundable (hereinafter
                    referred to as “Cancellation & Refund Policy”). Taxes may
                    apply on the subscription fee. Your paid Postick
                    Subscription will be activated only upon successful payment
                    of the Subscription Fee made through the Postick Platforms
                    as per the applicable subscription plan.
                  </p>
                  <h6>Subscription Cancellation & Refund</h6>
                  <p>
                    You may cancel your paid Postick Subscription any time by
                    contacting us.Due to service providers in nature “NO
                    REFUND”,“NO CANCELLATION” will be entertained once the
                    Payment has been made.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="contact" class="form-2">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h2>Contact Information</h2>
                <ul class="list-unstyled li-space-lg">
                  <li class="address">
                    Don't hesitate to give us a call or send us a contact form
                    message
                  </li>
                  <li>
                    <i class="fas fa-map-marker-alt"></i>Dhatiya Faliya, Nr.
                    Lugda Bazaar, Anam Ring Road, Bhuj-Kutch, 370001
                  </li>
                  <li>
                    <i class="fas fa-phone"></i>
                    <a class="turquoise" href="tel:+918128980289">
                      +91 81289 80289
                    </a>
                  </li>
                  <li>
                    <i class="fas fa-envelope"></i>
                    <a class="turquoise" href="mailto:office@Postick POS.com">
                      {/* office@Postick POS.com */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="map-responsive">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14663.71283719932!2d69.65686108021032!3d23.245699357446718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950e1a0117767b1%3A0x31c30e4cc118c6f2!2sShree%20Ram%20Technologies!5e0!3m2!1sen!2sin!4v1649169714818!5m2!1sen!2sin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="image-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    class="img-fluid"
                    src={require("./images/Contactus.gif")}
                    alt="alternative"
                    style={{ width: 350, height: 350 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="container">
            <div class="row" style={{ justifyContent: "space-between" }}>
              <div class="col-md-4">
                <div class="footer-col">
                  <h4>About Postick Pos</h4>
                  <p>
                    We're passionate about offering some of the best management
                    services for all types of restaurants
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-col last">
                  <h4>Social Media</h4>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/Postick-110506818235650"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-facebook-f fa-stack-1x"></i>
                    </a>
                  </span>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/pos.tick/"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-instagram fa-stack-1x"></i>
                    </a>
                  </span>
                  <span class="fa-stack">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/postick-pos-70649a234/"
                    >
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fab fa-linkedin-in fa-stack-1x"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <p class="p-small">
                  Copyright © 2020{" "}
                  <a href="http://www.postick.co.in">Postick</a> - All rights
                  reserved. <a href="/terms">{"Terms & Conditions"}</a>
                  {" | "}
                  <a href="/policy">{"Privacy Policy"}</a> {" | "}
                  <a href="/policy">{"Refund Policy"}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
